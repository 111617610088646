const EditPencil = ({
  width = 47,
  height = 48,
  fillColor = '#495EFF',
  strokeColor = '#fff',
  ...rest
}) => (
  <svg
    {...rest}
    width={width}
    height={height}
    viewBox='0 0 47 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='0.396545' width='47' height='47' rx='10' fill={fillColor} />
    <g clipPath='url(#clip0_2728_7079)'>
      <path
        d='M24.8174 15.8434L12.9194 27.6983C12.481 28.1357 12.204 28.7081 12.133 29.3227L11.6593 33.4702C11.5379 34.6156 12.3715 35.6416 13.5211 35.7626C13.598 35.7707 13.675 35.7745 13.7525 35.7739H13.9953L18.1578 35.3014C18.7719 35.2307 19.3454 34.959 19.7882 34.5297L31.6861 22.6635L24.8179 15.8434H24.8174Z'
        fill={strokeColor}
      />
      <path
        d='M35.7094 14.8412L32.6916 11.8342C31.6325 10.7747 29.9122 10.7715 28.8488 11.8267C28.8461 11.8294 28.8439 11.8315 28.8412 11.8342L26.0429 14.6224L32.9111 21.4658L35.7094 18.6776C36.7728 17.6223 36.7761 15.9083 35.717 14.8487C35.7143 14.846 35.7121 14.8439 35.7094 14.8412Z'
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_2728_7079'>
        <rect
          width='24.8615'
          height='24.7368'
          fill='white'
          transform='translate(11.6474 11.0377)'
        />
      </clipPath>
    </defs>
  </svg>
);
export default EditPencil;
